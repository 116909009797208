import React, { memo, useCallback, useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';

import { useAccountDetailsStyles } from './AccountDetails.style';

import { getOrganizationDetails } from '../../../../api/organizations';
import { selectedAccountDetails } from '../../../../state/atoms/accounts';
import { formatCurrency } from '../../../../utils/number';
import { OrganizationWithAccountDetails } from '../../../../models/organization';
import { googleSearchQuery } from '../../../../utils/map';

type AccountDetailsProps = {
  organizationId: string;
  setDetailsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  detailsLoaded: boolean;
};

const AccountDetails: React.FC<AccountDetailsProps> = ({
  organizationId,
  setDetailsLoaded,
  setHasError,
  detailsLoaded,
}) => {
  const classes = useAccountDetailsStyles();
  const [accountDetails, setAccountDetails] = useRecoilState(
    selectedAccountDetails(organizationId),
  );

  const loadDetails = useCallback(
    async (id: string) => {
      try {
        let details;
        if (id) {
          details = await getOrganizationDetails(id);
        } else {
          details = {} as OrganizationWithAccountDetails;
        }
        setAccountDetails({ loaded: true, account: details });
        setDetailsLoaded(true);
      } catch (error: any) {
        setHasError(true);
      }
    },
    [setAccountDetails, setDetailsLoaded, setHasError],
  );

  useEffect(() => {
    if (!detailsLoaded) {
      loadDetails(organizationId);
    } else {
      setDetailsLoaded(true);
    }
  }, [loadDetails, accountDetails, organizationId, setDetailsLoaded, detailsLoaded]);

  const {
    sfType: accountType,
    sfOwnerName: accountOwner,
    sfLastModifiedDate: modDate,
    emailAddresses: email,
    phoneNumber: phone,
    websites: website,
    description,
    volOfBusiness,
    account,
    primaryAccountData,
  } = accountDetails.account;

  const { specRateAWI, specRateUSG, specRateCT, specRateRockfon, winRate } = accountDetails.account;

  let specAWI = Number.isFinite(specRateAWI)
      ? Math.round((specRateAWI as number) * 100).toString() + '%'
      : 'N/A',
    specUSG = Number.isFinite(specRateUSG)
      ? Math.round((specRateUSG as number) * 100).toString() + '%'
      : 'N/A',
    specCertainTeed = Number.isFinite(specRateCT)
      ? Math.round((specRateCT as number) * 100).toString() + '%'
      : 'N/A',
    specRockFon = Number.isFinite(specRateRockfon)
      ? Math.round((specRateRockfon as number) * 100).toString() + '%'
      : 'N/A',
    win = Number.isFinite(winRate) ? Math.ceil(winRate * 100).toString() + '%' : 'NA',
    volume = volOfBusiness;

  if (primaryAccountData) {
    specAWI = Math.round((primaryAccountData.spec_rate_awi || 0) * 100).toString() + '%';
    specUSG = Math.round((primaryAccountData.spec_rate_usg || 0) * 100).toString() + '%';
    specCertainTeed =
      Math.round((primaryAccountData.spec_rate_certainteed || 0) * 100).toString() + '%';
    specRockFon = Math.round((primaryAccountData.spec_rate_rockfon || 0) * 100).toString() + '%';
    win = Math.round((primaryAccountData.win_rate || 0) * 100).toString() + '%';
    volume =
      (primaryAccountData.value_dodge || 0) +
      (primaryAccountData.value_cmd || 0) +
      (primaryAccountData.value_buildcentral || 0);
  } else if (account) {
    specAWI = Math.round((account.spec_rate_awi || 0) * 100).toString() + '%';
    specUSG = Math.round((account.spec_rate_usg || 0) * 100).toString() + '%';
    specCertainTeed = Math.round((account.spec_rate_certainteed || 0) * 100).toString() + '%';
    specRockFon = Math.round((account.spec_rate_rockfon || 0) * 100).toString() + '%';
    win = Math.round((account.win_rate || 0) * 100).toString() + '%';
    volume =
      (account.value_dodge || 0) + (account.value_cmd || 0) + (account.value_buildcentral || 0);
  }

  const dataRow = (label: string, data?: string | number | JSX.Element): JSX.Element | null => {
    if (!data) {
      return null;
    }

    return (
      <Grid className={classes.row}>
        <Typography className={classes.label} variant={'subtitle1'}>
          {label}:
        </Typography>
        <Typography variant={'body1'}>{data}</Typography>
      </Grid>
    );
  };

  return (
    <Grid className={classes.root}>
      {dataRow('Win Rate', win)}
      {accountType && (
        <Grid className={classes.row}>
          <Typography className={classes.label} variant={'subtitle1'}>
            Account Type:
          </Typography>
          <Typography variant={'body1'}>{accountType}</Typography>
        </Grid>
      )}
      {accountOwner && (
        <Grid className={classes.row}>
          <Typography className={classes.label} variant={'subtitle1'}>
            Account Owner:
          </Typography>
          <Typography variant={'body1'}>{accountOwner}</Typography>
        </Grid>
      )}
      <Box className={classes.specRates}>
        <Grid>
          <Typography variant={'subtitle1'}>Spec Rates</Typography>
          <Grid className={classes.specs}>
            <Grid className={classes.spec}>
              <Typography variant={'body1'}>Armstrong</Typography>
              <hr className={classes.dotted} />
              <Typography variant={'body1'}>{specAWI}</Typography>
            </Grid>
            <Grid className={classes.spec}>
              <Typography variant={'body1'}>USG</Typography>
              <hr className={classes.dotted} />
              <Typography variant={'body1'}>{specUSG}</Typography>
            </Grid>
            <Grid className={classes.spec}>
              <Typography variant={'body1'}>CertainTeed</Typography>
              <hr className={classes.dotted} />
              <Typography variant={'body1'}>{specCertainTeed}</Typography>
            </Grid>
            <Grid className={classes.spec}>
              <Typography variant={'body1'}>RockFon</Typography>
              <hr className={classes.dotted} />
              <Typography variant={'body1'}>{specRockFon}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {modDate && (
        <Grid className={clsx(classes.row, classes.lastMod)}>
          <Typography className={classes.label} variant={'subtitle1'}>
            Last Modified:
          </Typography>
          <Typography variant={'body1'}>{modDate}</Typography>
        </Grid>
      )}
      {
        <Link
          target={'_blank'}
          to={{ pathname: googleSearchQuery(accountDetails.account.fullAddress) }}
          className={clsx(classes.row, classes.hyperlink)}
          style={{ textDecoration: 'none' }}
        >
          <Typography variant={'body1'}>{accountDetails.account.fullAddress}</Typography>
        </Link>
      }
      {email?.[0] &&
        dataRow(
          'Email',
          <a className={classes.hyperlink} href={`mailto:${email?.[0]}`}>
            {email?.[0] || ''}
          </a>,
        )}
      {dataRow('Phone', phone || '')}
      {dataRow('Vol. of Business')}
      {website?.[0] &&
        dataRow(
          'Website',
          <a
            target={'_blank'}
            rel={'noopener noreferrer'}
            className={classes.hyperlink}
            href={`${website?.[0]}`}
          >
            {website?.[0] || '' || ''}
          </a>,
        )}
      {dataRow('Volume of Business', formatCurrency(volume || '0'))}
      <Grid className={classes.row} style={{ height: '20%', alignItems: 'start' }}>
        <Typography variant={'body1'}>
          <strong>Description:</strong> {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(AccountDetails);
